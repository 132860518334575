<template>
  <div>
    <c-card title="안전보호구/비상방재물품 목록 업로드 (동일한 관리번호는 자료가 수정됩니다.)" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="적용" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-3">
          <c-plant :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'device-equipment-excel-upload',
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
      },
      headerUrl: '',
      excelUploadInfo: {
        rowKeys: ['manageNo'], // 데이터들의 키 속성값
        taskClassCd: 'PSI_WASHING_SAFETY_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:110px',
            type: 'plant',
            sortable: false,
          },
          {
            name: 'manageNo',
            field: 'manageNo',
            label: '관리번호',
            style: 'width:120px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'areaName',
            field: 'areaName',
            label: '지역(공정)',
            style: 'width:120px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '품목',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'location',
            field: 'location',
            label: '비치장소',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'detailItemName',
            field: 'detailItemName',
            label: '세부품목',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'performanceStandard',
            field: 'performanceStandard',
            label: '성능 및 규격',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipAmount',
            field: 'equipAmount',
            label: '비치수량',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'checkCycle',
            field: 'checkCycle',
            label: '점검주기',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'left',
            sortable: false,
          },
          {
            name: 'deptName',
            label: '관리부서명',
            deptCd: 'deptCd',
            align: 'center',
            style: 'width:120px',
            type: 'dept',
            sortable: false,
          },
        ]],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.excelUploadInfo.columns.splice(1, 0, {
        name: 'safetyEquipTypeCd',
        field: 'safetyEquipTypeCd',
        label: '구분',
        type: 'select',
        comboItems: [
          { code: 'SETC000001', codeName: this.$language('안전보호장구') },
          { code: 'SETC000002', codeName: this.$language('비상방재물품') },
        ],
        align: 'center',
        style: 'width:130px',
        sortable: false,
      },)
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '업로드할 데이터가 없는 Sheet가 있습니다. 데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.',
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '에러가 있는 데이터가 존재합니다. 데이터 확인 후 적용하시길 바랍니다.',
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '해당 엑셀데이터를 업로드하면, 동일한 관리번호는 자료가 수정됩니다. 그대로 적용하시겠습니까?',
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
